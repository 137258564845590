<template>
	<v-container fluid style="max-width: 1400px">
		<v-card max-width="800" class="mx-auto mt-5" rounded="xl">
			<v-card-title>{{ $t('subscriptions.confirmPurchase') }}</v-card-title>
			<v-card-text>
				<p>{{ $t('subscriptions.confirmExplanation1') }}</p>
				<div class="text-center">
					<v-btn-toggle v-model="definitivePeriod" mandatory color="blue" rounded>
						<v-btn text value="monthly">
							<v-icon>{{ $t('subscriptions.monthly') }}</v-icon>
						</v-btn>
						<v-btn text value="yearly">
							<v-icon>{{ $t('subscriptions.yearly') }}</v-icon>
						</v-btn>
					</v-btn-toggle>
					<span v-if="definitivePeriod == 'monthly'">{{ $t('subscriptions.billedEachMonth') }}</span>
					<span v-else-if="definitivePeriod == 'yearly'">{{ $t('subscriptions.billedEachYear') }}</span>
				</div>
				<v-alert
					v-if="setupInfo.company.invoiceIncomplete"
					border="top"
					elevation="8"
					type="warning"
					color="orange darken-3"
					max-width="500"
					dark
					class="mx-auto my-4 text-uppercase text-center"
				>
					<v-btn text :to="{ name: 'SettingsSubscriptions' }">
						{{ $t('invoices.invoiceInfoNeeded') }}
					</v-btn>
				</v-alert>
				<v-divider class="my-2 mx-2" />
				<p>
					<span>{{ $t('subscriptions.subscription') }}: </span>
					<span class="text-h5 mx-2">{{ pricingItem.title }}</span>
				</p>
				<p>
					<span class="text-body-1">{{ $t('subscriptions.price') }}: </span>
					<span class="text-h4 mx-2">{{ price }}</span> <span class="text-body-1"> /{{ periodText }}</span>
				</p>
				<v-card-text>
					<v-list class="py-0" flat dense>
						<v-subheader class="text-body-1">{{ $t('subscriptions.includes') }}:</v-subheader>
						<v-list-item>
							<v-list-item-content>
								<span>{{ pricingItem.item1 }}</span>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-content>
								<span>{{ pricingItem.item2 }}</span>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="pricingItem.item3">
							<v-list-item-content>
								<span>{{ pricingItem.item3 }}</span>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="pricingItem.item4">
							<v-list-item-content>
								<span>{{ pricingItem.item4 }}</span>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card-text>
				<p class="text-body-1 text-justify">
					{{ $t('subscriptions.confirmExplanation2') }}
				</p>
				<p class="text-body-1 text-justify">
					{{ $t('subscriptions.confirmExplanation3') }}
				</p>
				<p v-if="definitivePeriod == 'yearly'" class="text-body-1 text-justify">
					{{ $t('subscriptions.confirmExplanation4Yearly') }}
				</p>
				<p v-else-if="definitivePeriod == 'monthly'" class="text-body-1 text-justify">
					{{ $t('subscriptions.confirmExplanation4Monthly') }}
				</p>
				<i18n path="subscriptions.confirmExplanation5" tag="p" class="text-body-1 text-justify">
					<template #terms>
						<router-link class="text-subtitle-2 text-decoration-none red--text" :to="{ name: 'PoliciesTerms' }" target="_blank">
							{{ $t('policies.terms&conditions') }}
						</router-link>
					</template>
				</i18n>
				<p class="text-body-1 text-justify">
					{{ $t('subscriptions.confirmExplanation6') }}
				</p>
				<div class="mx-auto" style="max-width: 300px">
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-right">{{ $t('auth.name') }}</th>
									<th class="text-center">{{ $t('subscriptions.price') }}</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td class="text-right">{{ $t('subscriptions.subscription') }} {{ pricingItem.title }}</td>
									<td class="text-center">{{ price }}</td>
								</tr>
								<tr>
									<td class="text-right">{{ $t('subscriptions.iva') }} 21%</td>
									<td class="text-center">{{ computedIVA }}</td>
								</tr>
								<tr>
									<td class="text-right">{{ $t('subscriptions.total') }}</td>
									<td class="text-center">{{ computedTotal }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</div>
			</v-card-text>
			<v-card-actions class="d-flex justify-center align-end">
				<v-spacer />
				<v-btn class="mx-2" color="red" rounded @click="cancel()">
					{{ $t('offers.cancel') }}
				</v-btn>
				<v-btn class="mx-2" color="blue" rounded @click="subscribe()" :loading="loading">
					{{ $t('subscriptions.subscribe') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('subscriptions.confirmPurchase')
		}
	},
	props: {
		period: {
			type: String,
			required: true
		},
		pricingItem: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			definitivePeriod: ''
		}
	},
	created() {
		this.definitivePeriod = this.period
	},
	computed: {
		price() {
			if (this.definitivePeriod == 'monthly') return this.formatPrice(this.pricingItem.priceMonthly)
			else if (this.definitivePeriod == 'yearly') return this.formatPrice(this.pricingItem.priceYearly)
			else return this.formatPrice(0)
		},
		computedIVA() {
			return this.formatPrice(parseFloat(this.price) * 0.21)
		},
		computedTotal() {
			return this.formatPrice(parseFloat(this.price) * 1.21)
		},
		periodText() {
			if (this.definitivePeriod == 'monthly') return this.$t('subscriptions.month')
			else return this.$t('subscriptions.year')
		},
		letSubscribe() {
			return !!this.authenticated && !!this.company.imgURL
		},
		recommendedClass() {
			return {
				'border-color': 'green',
				'border-width': '4px',
				'border-style': 'solid',
				'border-radius': '8px',
				transform: 'scale(1.15)'
			}
		},
		...mapGetters({
			company: 'companies/company',
			authenticated: 'auth/authenticated',
			setupInfo: 'user/setupInfo'
		})
	},
	methods: {
		formatPrice(price) {
			return Number((Math.round(parseFloat(price) * 100) / 100).toFixed(2)).toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })
		},
		subscribe() {
			this.loading = true
			this.purchaseSubscription({
				companyID: this.company.id,
				period: this.definitivePeriod,
				typeID: this.pricingItem.typeID
			})
				.then(({ success, subscription }) => {
					if (success) {
						this.$router.push({ name: 'Subscription', params: { id: subscription.id } })
					}
				})
				.then(() => {
					this.loading = false
				})
		},
		cancel() {
			this.$router.push({ name: 'Pricing' })
		},
		...mapActions('subscriptions', ['purchaseSubscription'])
	}
}
</script>
